import { createGlobalStyle } from 'styled-components';

import animations from '../styles/animations';
import buttons from '../styles/buttons';
import columns from '../styles/columns';
import global from '../styles/global';
import reset from '../styles/reset';
import spacing from '../styles/spacing';
import typography from '../styles/typography';
import utils from '../styles/utils';

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${typography}
  ${global}
  ${buttons}
  ${utils}
  ${columns}
  ${animations}
  ${spacing}
  ${(props) => props.theme.FontFaces}
  ${(props) => props.theme.fonts}
`;

export default GlobalStyle;
