import { css } from 'styled-components';

// TODO: need to review this
const spacingSet = [0, 5, 50, 60];

function spacing() {
  let styles = '';

  for (let i = 0; i < spacingSet.length; i += 1) {
    const val = spacingSet[i];
    styles += `

      .u-m-a-${val} {
        margin: ${val}px !important;
      }

      .u-m-t-${val} {
        margin-top: ${val}px !important;
      }

      .u-m-r-${val} {
        margin-right: ${val}px !important;
      }

      .u-m-b-${val} {
        margin-bottom: ${val}px !important;
      }

      .u-m-l-${val} {
        margin-left: ${val}px !important;
      }

      .u-my-${val} {
        margin-top: ${val}px !important;
        margin-bottom: ${val}px !important;
      }

      .u-mx-${val} {
        margin-left: ${val}px !important;
        margin-right: ${val}px !important;
      }

      // padding
      .u-p-a-${val} {
        padding: ${val}px !important;
      }

      .u-p-t-${val} {
        padding-top: ${val}px !important;
      }

      .u-p-r-${val} {
        padding-right: ${val}px !important;
      }

      .u-p-b-${val} {
        padding-bottom: ${val}px !important;
      }

      .u-p-l-${val} {
        padding-left: ${val}px !important;
      }

      .u-py-${val} {
        padding-top: ${val}px !important;
        padding-bottom: ${val}px !important;
      }

      .u-px-${val} {
        padding-left: ${val}px !important;
        padding-right: ${val}px !important;
      }
     `;
  }

  return css`${styles}`;
}

export default spacing;
