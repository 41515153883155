import { css } from 'styled-components';

import { Breakpoints } from '@powdr/constants';

// TODO: need to review getRowStyles works properly
const rowSet = [5, 10, 15, 20, 25, 30];

function getRowStyles() {
  let styles = '';

  for (let i = 0; i < rowSet.length; i += 1) {
    const val = rowSet[i];
    styles += `
      .row-${val} {
        margin-right: -${val}px !important;
        margin-left: -${val}px !important;
      }
    `;
  }

  return css`${styles}`;
}

// TODO: need to review getColumnStyles works properly
// TODO: what would it take to convert to CSS grid?
const columnWidth = 65;
const gutterWidth = 0;
const columnLength = 12;
const marginSets = [5, 10, 15, 20];
const paddingSets = [15, 20, 25, 30];

function getColumnStyles() {
  let styles = '';
  for (let i = 0; i < columnLength; i += 1) {
    // Set container width relative to user variables
    const width = (columnWidth * columnLength) + (gutterWidth * (columnLength - 1));

    // First, calculate grid size in pixels
    const gridSize = (columnWidth * i) + (gutterWidth * (i - 1));
    // Then, calculate the responsive grid
    const grid = (gridSize / width) * 100;

    styles += `
      .col-${i}of${columnLength} {
        width: ${grid}%; height: auto;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
      }
    `;

    for (let p = 0; p < paddingSets.length; p += 1) {
      styles += `
        .col-${i}of${columnLength}-p-${paddingSets[p]} {
          padding-left: ${paddingSets[p]}px;
          padding-right: ${paddingSets[p]}px;
          width: ${grid}%;
          height: auto;
        }
      `;
    }

    for (let m = 0; m < marginSets.length; m += 1) {
      styles += `
        .col-${i}of${columnLength}-m-${marginSets[m]} {
          margin-left: ${marginSets[m]};
          margin-right: ${marginSets[m]};
          width: calc(${grid} - ${marginSets[m] * 2}px);
          height: auto;
        }
      `;
    }
  }

  return css`${styles}`;
}

const columns = css`
  /************************/
  /* Layout */
  /************************/
/* 
  .container {
    width: 100%;
    padding: 0 7.5%;

    ${Breakpoints.smaller(css`
      padding: 0 30px;
    `)}
  } */

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  [class^="col-"], [class*=" col-"] {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    &.col, &.left, &.right {
      ${Breakpoints.smallest(css`
        display: block;
        width: auto;
      `)}
      }
    }
  }

  ${getRowStyles}

  ${getColumnStyles}

  .col-1of5,
  .col-1of7  {
    height: auto;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .col-1of5 {
    width: 20%;
    height: auto;
  }

  .col-1of7 {
    width: 14.285%;
    height: auto;
  }

  .col-1of5-p-25 {
    padding-left: 25px;
    padding-right: 25px;
    width: 20%;
    height: auto;
  }
`;

export default columns;
