import { css } from 'styled-components';

// TODO: need to review this
const sets = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
function roundCSS() {
  let styles = '';

  for (let i = 0; i < sets.length; i += 1) {
    const val = sets[i];
    styles += `
      &.m-round-p-${val} {
        border-radius: 50%;
        padding: ${val}px ${val}px;
      }

      &.m-round-${val}-p-${val} {
        border-radius: ${val}px;
        padding: ${val}px ${val}px;
      }
     `;
  }

  return css`${styles}`;
}

const buttons = css`
  .btn,
  a.btn,
  button.btn {
    ${({ theme }) => theme.fonts.PRI_BDY_HVY};
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    font-size: ${({ theme }) => theme.FontSize.REG30};
    text-transform: none;
    margin: 0;

    &.btn.m-no-color {
      background-color: transparent !important;
    }

    //TODO need to bring in color from theme or move buttons to a global component
    /* background-color: #18D5EE; */
  }

  ${roundCSS}
`;

export default buttons;
