/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-unresolved
import { css } from 'styled-components';

import { HeaderTransformOptions } from '@powdr/constants';

// TODO: Convert all @includes from the `powdr-ui` core scss `client/assets/core/scss/_core.scss`
const typography = css`

  h1, h2, h3, h4, h5, h6 {
    line-height: normal;
  }

  h1,
  h2 {
    ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  }
  h3,
  h4,
  h5,
  h6 {
    ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

 /************************/
/* Typography */
/************************/

h1 {
  ${({ theme }) => ((theme?.HeaderTransform) ? `text-tranform: ${theme.HeaderTransform};` : 'text-transform: uppercase;')};
}

h2 {
  ${({ theme }) => ((theme?.HeaderTransform) ? `text-tranform: ${theme.HeaderTransform};` : 'text-transform: uppercase;')};
}

h3 {
  ${({ theme }) => ((theme?.HeaderTransform) ? `text-tranform: ${theme.HeaderTransform};` : 'text-transform: uppercase;')};
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

`;

export default typography;
